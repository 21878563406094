<template>
  <article class="disclosure">
    <h1>Audi Fleet Charging</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of July 1, 2020</strong></p>
    <p>
      Electrify America and Audi are pleased to provide this promotional charging program to select Audi e-tron drivers.
      By clicking “Agree” below, you agree to participate in this promotion, subject to your compliance with these terms
      and conditions.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will receive an initial 100 kilowatt hours
      (kWh) (or, for charging stations where pricing is calculated on a per-minute basis, the per-minute equivalent of
      100 kWh) of included charging on the Audi e-tron provided to you by your employer or other organization. You may
      redeem this credit on Electrify America’s network of electric vehicle charging stations. Within 72 hours of your
      registration, you will be enrolled in the full promotional program, which may increase the number of complimentary
      kWh to the total amount available for the registered e-tron under the plan selected by your organization. After
      your enrollment, please refer to the Plan Details screen in the Electrify America app, accessible by navigating to
      the <strong>Profile</strong> icon in top right hand corner of map and selecting
      <strong>“Plan Details,”</strong> to track the total amount of complimentary kWh associated with your account.
    </p>
    <p>There is no additional fee, purchase, or subscription required for your access to this promotion.</p>
    <p>
      After signing up, you can redeem this complimentary credit during your initiation of a charging session at any
      Electrify America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the appropriate plan selected. This complimentary charging may not be initiated using the interface
      screen or buttons on the charger.
    </p>

    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this complimentary credit for charging the e-tron whose VIN you submitted when
      signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account</strong>, whether owned by you or
      others.
    </p>
    <p>
      Complimentary charging time <strong>does not include</strong> any parking or idle fees assessed at the charging
      station.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the e-tron when charging
      at an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the e-tron at the charging
      station.
    </p>
    <p>
      You acknowledge that you forfeit any remaining credit from this promotion on your account when you return the
      e-tron to your employer or other organization.
    </p>

    <p>
      After you have used all of the available credit at Electrify America charging stations, you will not be able to
      reload or add more credit to your promotional plan. In addition, this promotion will automatically end forty-eight
      (48) months after the date that the e-tron is first registered for this program, following which you will not be
      able to access or use the remaining kWh credit on your account from this promotion (if any).
      <strong
        >Please note that the duration of this credit may be subject to change depending on the parameters of the
        promotional program selected by your organization.</strong
      >
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at <router-link :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <strong>“Terms of Use”</strong> & <strong>“Privacy Policy.”</strong> Please read these Terms of Use
      documents carefully for information about how you can pay through your account for charging session time outside
      of the complimentary kWh (or per-minute equivalent) offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion. Following the termination or expiration of this program
      for any reason, your access to the complimentary promotion will immediately cease, and you may not use or redeem
      any remaining portion of the complimentary charging time. At that time, your account will automatically convert to
      a basic Electrify America “Pass” account, at no additional fee or charge to you (neither one-time nor recurring).
      You may, but are not obligated to, sign up for a different type of subscription via the Electrify America mobile
      app.
    </p>
    <br /><br />
  </article>
</template>

<script>
export default {
  name: 'audifleet-disclosure',
  metaInfo: {
    title: 'Audi Fleet Charging | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/audi-fleet-charging-disclosure/' }],
  },
};
</script>
